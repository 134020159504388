import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "channels";
import Swiper from "swiper";
import "swiper/swiper-bundle.min.css";

Rails.start();
Turbolinks.start();
ActiveStorage.start();

function bindDeckSelection() {
  document.querySelectorAll('.card-body').forEach(cardBody => {
    cardBody.addEventListener('click', event => {
      const deckId = event.currentTarget.closest('.card').dataset.deckId;
      if (deckId) {
        Rails.ajax({
          url: `/select_deck/${deckId}`,
          type: 'POST',
        });
      }
    });
  });
}

// Event listener for Turbolinks load
document.addEventListener('turbolinks:load', () => {
  bindDeckSelection();

  // Hide skeleton loaders after the content is fully loaded
  setTimeout(() => {
    document.querySelectorAll('.skeleton-loader').forEach(skeleton => {
      skeleton.remove(); // Removes the skeleton element
    });
    document.querySelectorAll('.mySwiper').forEach(swiper => {
      swiper.style.display = 'block';
    });
  }, 1000); // Adjust timeout as needed

  if (document.querySelector(".stripe-checkout")) {
    var stripe = Stripe('<%= ENV["STRIPE_PUBLISHABLE_KEY"] %>');
    document.querySelectorAll(".stripe-checkout").forEach(button => {
      button.addEventListener("click", (event) => {
        event.preventDefault();
        fetch(button.dataset.checkoutUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": Rails.csrfToken()
          }
        })
        .then(response => response.json())
        .then(session => {
          return stripe.redirectToCheckout({ sessionId: session.id });
        })
        .then(result => {
          if (result.error) {
            alert(result.error.message);
          }
        });
      });
    });
  }

  function hideAlerts() {
    const alerts = document.querySelectorAll('.alert, .notice');
    if (alerts) {
      setTimeout(() => {
        alerts.forEach(alert => alert.classList.add('hide'));
      }, 2000);

      setTimeout(() => {
        alerts.forEach(alert => alert.style.display = 'none');
      }, 2500);
    }
  }

  hideAlerts();
});

// // Expose the functions to the global scope for use in ERB files
// window.bindDeckSelection = bindDeckSelection;

// document.addEventListener('visibilitychange', function() {
//   if (document.visibilityState === 'visible') {
//     location.reload();  // Reload the page when the tab becomes active
//   }
// });
